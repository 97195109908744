import React, { useState, useRef, useEffect } from 'react';
import Container from '@mui/material/Container';
import { TextField } from '@mui/material';
import Typography from '@mui/material/Typography';


const NumberInput = (props) => {

  const { title = "Numbers", numberCount = 4, isSelected = false, setParentNumbers } = props;
  const numberRefs = [useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null),useRef(null)];
  const [numbers, setNumbers] = useState([0,0,0,0]);

  useEffect(() => {
    if (isSelected) {
      numberRefs[0].current.focus();
    }
    // eslint-disable-next-line
  }, []);
  
  const handleInput = (e, index) => {

    let newNumbers = [...numbers];
    if (e.key === "ArrowRight") {
      let updateIndex = Math.min(index+1,newNumbers.length-1);
      numberRefs[updateIndex].current.focus();
    }
    else if (e.key === "ArrowLeft") {
      let updateIndex = Math.max(0,index-1);
      numberRefs[updateIndex].current.focus();
      
    }
    else if (e.key === "Backspace") {
      if (newNumbers[index] !== '') {
        newNumbers[index] = '';
      }
      else {
        let updateIndex = Math.max(0,index-1);
        numberRefs[Math.max(0,index-1)].current.focus();
        newNumbers[updateIndex] = '';
      }
      
    }
    else if (!isNaN(e.key)) {
        newNumbers[index] = e.key;
        let updateIndex = Math.min(index+1,newNumbers.length-1);
        numberRefs[updateIndex].current.focus();
      
    }

    setNumbers(newNumbers);
    if (setParentNumbers) {
      setParentNumbers(newNumbers);
    }
    
  }

  const numberBoxes = [...Array(numberCount)].map((e, i) => (
      <TextField key={i} inputRef={numberRefs[i]} value={numbers[i]} onKeyDown={(e) => handleInput(e, i)} 
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', fontWeight: 'bold', fontSize: '150%' }} 
          sx={{
            width: '5ch',
            marginRight: {xs:'5px', md:'20px' },
            marginLeft: {xs:'5px', md:'20px' }
          }}
          />
      )
  );

  return (
      <Container className="container" maxWidth="md">
        <Typography
              component="h1"
              variant="h2"
              align="center"
              color="text.primary"
              gutterBottom
            >
              {title}
        </Typography>
        {numberBoxes}
      </Container>
  );
};

export default NumberInput;
