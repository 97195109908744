import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import DatasetIcon from '@mui/icons-material/Dataset';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import NumberInput from './NumberInput';
import * as APIGService from './APIGService';
import PredictionCard from './PredictionCard';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Neradox
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();


export default function Calculator() {

  
  const [cards, setCards] = useState([]);
  const [baseNumber, setBaseNumber] = useState([0,0,0,0]);
  const [previousWinner, setPreviousWinner] = useState([0,0,0,0]);
  
  const generateNumbers = () => {
    setCards([]);
    APIGService.getNumbers(baseNumber,previousWinner)
      .then(result => {
 
        let sums = {
          title: "Number Sums",
          numbers: result["sums"]
        };

        let mirrorCard = {
          title: "Mirror Numbers",
          numbers: result["mirror"]
        };

        let carry = {
          title: "Carry Numbers",
          numbers: result["carry"]
        };

        let flip1 = {
          title: "Flip 1 Numbers",
          numbers: result["flip1"]
        };

        let flip2 = {
          title: "Flip 2 Numbers",
          numbers: result["flip2"]
        };

        setCards([sums, mirrorCard, carry, flip1, flip2]);
      });
  };

  let idx = 0;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <DatasetIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Lotto Calculator
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            bgcolor: 'background.paper',
            pt: 8,
            pb: 6,
          }}
        >
          <Container className="container" maxWidth="md">
            
            <Stack
              sx={{ pt: 4 }}
              direction={{xs:"column", md:"row"}}
              spacing={2}
              justifyContent="center"
            >
              <NumberInput title="Base Numbers" setParentNumbers={setBaseNumber} numberCount={4} isSelected={true} />
              <NumberInput title="Previous Winners" setParentNumbers={setPreviousWinner} numberCount={4} />
            </Stack>
            <Stack
              sx={{ pt: 4 }}
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              <Button onClick={generateNumbers} variant="contained">Generate</Button>
            </Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth="lg">
          {/* End hero unit */}
          <Grid container spacing={3}>
            {cards.map((card) => (
              <Grid item key={idx++} xs={12} sm={6} md={4}>
                <PredictionCard title={card.title} numbers={card.numbers}/>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        {/* <Typography variant="h6" align="center" gutterBottom>
          Footer
        </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Something here to give the footer a purpose!
        </Typography> */}
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  );
}