import { API } from "aws-amplify";

const APIName = "ReDesign";

export const getNumbers = async (baseNumber, previousWinner) => {
    try {
        let request = {
            body: {
                baseNumber: baseNumber,
                previousWinner: previousWinner
            }
          }
        let documents = await API.post(APIName, "/lottonumbers", request);
        // console.log(documents);
        return documents.body;
    }
    catch (e) {
        console.log(e);
    }
    
}